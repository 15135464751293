/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
 
require('./bootstrap');
//require('./datetimepicker');
//require('./countdown');
//require('./nprogress');
//require('./jalalimoment');
require('datatables.net-bs4');
require('datatables.net-responsive-bs4');

import $ from 'jquery';
window.$ = window.jQuery = $;

//import moment from 'moment';
import * as moment from 'moment';
window.moment = moment; 

//import 'jquery-ui/ui/widgets/autocomplete.js';
//import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/droppable.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'jquery-ui/ui/widgets/spinner.js';
import 'jquery-ui/ui/widgets/tabs.js';
import 'jquery-ui/ui/widgets/tooltip.js'; 

//import 'jquery-datetimepicker';
require('jquery-datetimepicker');
window.datetimepicker = 'jquery-datetimepicker';

require('tinymce');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

require('./components');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

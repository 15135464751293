//Vue.component('example', require('./components/Example.vue'));
//Vue.component('widget-grid', require('./components/WidgetGrid.vue'));
//Vue.component('marketing-image-grid', require('./components/MarketingImageGrid.vue'));
//Vue.component('chat-message', require('./components/ChatMessage.vue'));
//Vue.component('chat-list', require('./components/ChatList.vue'));
//Vue.component('chat-create', require('./components/ChatCreate.vue'));
//Vue.component('category-grid', require('./components/CategoryGrid.vue'));
//Vue.component('subcategory-grid', require('./components/SubcategoryGrid.vue'));
//Vue.component('classroom-category-grid', require('./components/ClassroomCategoryGrid.vue'));
//Vue.component('classroom-subcategory-grid', require('./components/ClassroomSubcategoryGrid.vue'));
//Vue.component('lesson-grid', require('./components/LessonGrid.vue'));
//Vue.component('lesson-create-category', require('./components/LessonCreateCategory.vue'));
//Vue.component('lesson-edit-category', require('./components/LessonEditCategory.vue'));
//Vue.component('data-extracted-sample', require('./components/DataExtractedSample.vue'));

// Event Manager
Vue.component('places-index', require('./components/events/PlacesIndex.vue').default);
Vue.component('shows-index', require('./components/events/ShowsIndex.vue').default);